.admin-news {
  &__item {
    display: flex;
    align-items: flex-start;
  }

  &__tag {
    margin-left: 10px !important;
    &:first-child {
      margin-left: 0 !important;
    }
  }
}

.admin-listview {
  &__content {
    padding-left: 15px;
  }

  &__item {
    padding: 1.25rem 0.2rem;
  }
}

label.admin-label {
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin: 10px 0;
  background: rgba(0, 0, 0, 0.3);
}

.admin-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
