/*=============================
 Preloader
===============================*/
#preloader {
    position       : fixed;
    display        : flex;
    align-items    : center;
    justify-content: center;
    background     : var(--tg-black-two);
    z-index        : 999;
    width          : 100%;
    height         : 100%;
}

.spinner {
    margin    : 100px auto;
    width     : 50px;
    height    : 40px;
    text-align: center;
    font-size : 10px;
}

.spinner>div {
    background-color : var(--tg-white);
    height           : 100%;
    width            : 6px;
    display          : inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation        : sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay        : -1.1s;
    margin:0px 2px;
}

.spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay        : -1.0s;
    margin-right: 2px;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay        : -0.9s;
    margin-right: 2px;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay        : -0.8s;
}

@-webkit-keyframes sk-stretchdelay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
    }

    20% {
        -webkit-transform: scaleY(1.0);
    }

    ;
}

@keyframes sk-stretchdelay {

    0%,
    40%,
    100% {
        transform        : scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform        : scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }

    ;
}